<template>
  <div class="markup-tables flex">
    <va-card class="flex mb-4">
      <va-card-title style="font-size: 1.5rem;">Joint Sessions</va-card-title>
      <va-card-content>
        <div class="row">
          <va-select
            class="flex mb-2 md2 xs12"
            :options="filterTypesAdvanced"
            v-model="filterType"
            label="Filter Types"
            placeholder="Select Type"
            :clearable="false"
            v-if="filterModeSetting"
          />
          <va-select
            class="flex mb-2 md2 xs12"
            :options="filterTypes"
            v-model="filterType"
            label="Filter Types"
            placeholder="Select Type"
            :clearable="false"
            v-else
          />
          <va-select
            class="flex mb-2 md2 xs12"
            :options="filterSubTypes"
            v-model="filterSubType"
            label="Functional Types"
            placeholder="Select Type"
            :clearable="false"
            v-if="filterType === 'Functional'"
          />
          <div class="flex mb-2 md3" v-if="filterType === 'Temporal'">
            <div class="row">
              <va-date-input
                class="flex mb-2 md8 xs8"
                v-model="filterDateStart"
                label="Start Date"
              />
              <va-time-input
                class="flex mb-2 md4 xs4"
                v-model="filterTimeStart"
              />
            </div>
            <div class="row" style="margin-top: -1.5rem; margin-bottom: -1.5rem;">
              <va-date-input
                class="flex mb-2 md8 xs8"
                v-model="filterDateEnd"
                label="End Date"
              />
              <va-time-input
                class="flex mb-2 md4 xs4"
                v-model="filterTimeEnd"
              />
            </div>
          </div>
          <va-select
            class="flex mb-2 md2 xs12"
            :options="usersSelect"
            v-model="filterText"
            label="Users"
            placeholder="Select User"
            :clearable="false"
            multiple
            v-if="filterType === 'Functional' && filterSubType !== 'Data Mode'"
          />
          <va-input
            class="flex mb-2 md2 xs12"
            placeholder="Filter..."
            v-model="filterText"
            v-on:keyup.enter="addFilter"
            v-else-if="filterType === 'All' || filterType === 'Name' || filterType === 'Spatial' || !filterType"
          />
          <va-select
            class="flex mb-2 md2 xs12"
            :options="operators"
            v-model="operator"
            label="Operator"
            placeholder="Select Operator"
            :clearable="false"
            v-if="filtersTypes.length > 0 && !filterModeSetting"
          />
          <va-select
            class="flex mb-2 md2 xs12"
            :options="operatorsForFirst"
            v-model="operator"
            label="Operator"
            placeholder="Select Operator"
            :clearable="false"
            v-else-if="filtersTypes.length <= 0 && !filterModeSetting"
          />
          <div class="flex mb-2 md1 xs12" :class="isMobile ? 'text-center' : 'text-left'" style="padding-right: 4rem;" v-if="(filterType !== 'Temporal' && filterText.length > 0) || (filterType === 'Temporal' && filterDateStart)">
            <va-button size="small" class="link" @click="addFilterAdvanced" v-if="isMobile && filterModeSetting">Add</va-button>
            <va-button size="small" class="link" @click="addFilter" v-if="isMobile && !filterModeSetting">Add</va-button>
            <va-icon :size="'2rem'" :name="'fa4-plus-square'" class="link" @click="addFilterAdvanced" v-else-if="!isMobile && filterModeSetting" />
            <va-icon :size="'2rem'" :name="'fa4-plus-square'" class="link" @click="addFilter" v-else-if="!isMobile && !filterModeSetting" />
          </div>
          <div class="flex mb-2 md5 xs12" :class="isMobile ? 'text-center' : 'text-right'" style="padding-right: 4rem;" v-if="filterText.length > 0 && selectedItemsAll.length > 0 && (filterType === 'All' || filterType === 'Name' || filterType === 'Spatial' || !filterType)">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" @click="visualizeFiles" />
          </div>
          <div class="flex mb-2 md4 xs12" :class="isMobile ? 'text-center' : 'text-right'" style="padding-right: 4rem;" v-else-if="filterText.length > 0 && selectedItemsAll.length > 0 && filterType === 'Temporal'">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" @click="visualizeFiles" />
          </div>
          <div class="flex mb-2 md3 xs12" :class="isMobile ? 'text-center' : 'text-right'" style="padding-right: 4rem;" v-else-if="filterText.length > 0 && selectedItemsAll.length > 0 && filterType === 'Functional'">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" @click="visualizeFiles" />
          </div>
          <div class="flex mb-2 md6 xs12" :class="isMobile ? 'text-center' : 'text-right'" style="padding-right: 4rem;" v-else-if="filterText.length <= 0 && selectedItemsAll.length > 0 && (filterType === 'Spatial' || filterType === 'Name' || !filterType)">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" @click="visualizeFiles" />
          </div>
          <div class="flex mb-2 md5 xs12" :class="isMobile ? 'text-center' : 'text-right'" style="padding-right: 4rem;" v-else-if="filterText.length <= 0 && selectedItemsAll.length > 0 && filterType === 'Temporal'">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" @click="visualizeFiles" />
          </div>
          <div class="flex mb-2 md4 xs12" :class="isMobile ? 'text-center' : 'text-right'" style="padding-right: 4rem;" v-else-if="filterText.length <= 0 && selectedItemsAll.length > 0 && filterType === 'Functional'">
            <va-icon :size="'2rem'" :name="'fa4-trash'" style="padding-right: 1rem; cursor: pointer; color: red;" @click="removeMultiModal" />
            <va-icon :size="'1.6rem'" :name="'material-icons-map'" class="link" @click="visualizeFiles" />
          </div>
        </div>
        <div class="row">
          <div class="flex xs12">
            <va-chip :outline="!filtersTypes[i].startsWith('|')" class="mb-2 mr-2" color="primary" v-for="(filter, i) in filters" v-bind:key="`chip${i}`">
              {{ filter }}&nbsp;<va-icon :size="'1rem'" :name="'fa4-remove'" style="cursor: pointer;" @click="removeFilter(i)" />
            </va-chip>
          </div>
        </div>
        <div class="row" v-if="filterModeSetting">
          <div class="va-input-wrapper va-input va-input--solid flex xs7">
            <div class="va-input-wrapper__content">
              <div class="va-input__container" style="">
                <div class="va-input__content-wrapper">
                  <div class="va-input__content">
                    <input type="text" tabindex="0" v-model="advancedFilter" aria-label="" class="va-input__content__input">
                  </div>
                </div>
                <div class="va-input__icons">
                </div>
              </div>
              <div class="va-input-wrapper__message-list-wrapper">
                <span class="text-danger" v-if="filterSyntaxError">Invalid filter syntax!</span>
              </div>
            </div>
          </div>
          <div class="flex xs4">
            <va-button @click="applyAdvancedFilter">Apply Filter</va-button>
            <va-button style="margin-left: 1rem;" @click="addAND">AND</va-button>
            <va-button color="secondary" @click="addOR">OR</va-button>
          </div>
          <div class="flex xs1">
            <va-icon :name="'fa4-flask'" class="experiment-icon" @click="filterMode"/>
          </div>
        </div>
        <div class="row" v-else>
          <div class="flex xs11">
            <va-alert class="mt-3" color="primary" outline  v-if="selectedItemsAll.length > 0">
              <span>
                Number of selected items:
                <va-chip class="ml-2 chip-hover" @click="clearSelection">{{ selectedItemsAll.length }}</va-chip>
              </span>
            </va-alert>
          </div>
          <div class="flex xs1">
            <va-icon :name="'fa4-flask'" class="experiment-icon" @click="filterMode"/>
          </div>
        </div>
        <va-data-table
          :items="filtered"
          :columns="columns"
          :per-page="perPage"
          :current-page="1"
          :selectable="true"
          :loading="isSessionsLoading"
          @sorted="sortChange"
          @selection-change="selectionChange"
          v-model="selectedItems"
        >
          <template #bodyAppend>
            <tr>
              <td colspan="4" class="table-example--pagination">
                <va-pagination
                  v-model="currentPage"
                  input
                  :pages="pages"
                />
              </td>
              <td colspan="3" class="table-example--pagination">
                <va-select
                  style="width: 8rem;"
                  v-model="perPage"
                  :label="'Per Page'"
                  :options="perPageOptions"
                  noClear
                />
              </td>
            </tr>
          </template>
          <template #cell(label)="{ value, rowIndex }">
            <va-popover
              class="mr-2 mb-2"
              :message="filtered[rowIndex]['sessions'].toString()"
              color="primary"
            >
              {{ value }}
            </va-popover>
          </template>
          <template #cell(uid)="{ source: uid }">
            <router-link :key="uid" :to="{ name: 'jointgroupedfiles', params: { id: uid }}">
              <va-icon :name="'fa4-eye'"/>
            </router-link>
            <va-icon :name="'fa4-trash'" style="padding-left: 1rem; cursor: pointer; color: red;" @click="removeModal(uid, label)" />
          </template>
        </va-data-table>
        <va-alert class="mt-3" border="left" v-if="filters.length > 0">
          <span>
            Number of filtered items:
            <va-chip>{{ filteredCount }}</va-chip>
          </span>
        </va-alert>
      </va-card-content>
    </va-card>

    <va-modal
      v-model="removeModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove a joint session</h2>
      </template>
      <slot>
        <div>Do you want to remove {{ removeName }}?</div>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeModalConfirm">Delete</va-button>
      </template>
    </va-modal>

    <va-modal
      v-model="removeMultiModalShow"
      hide-default-actions
      overlay-opacity="0.2"
      size="large"
    >
      <template #header>
        <h2>Remove a joint sessions</h2>
      </template>
      <slot>
        <div>Do you want to remove the following joint sessions?</div>
        <br />
        <p v-for="removeMultiName in removeMultiNames">{{ removeMultiName }}</p>
      </slot>
      <template #footer>
        <va-button class="mr-2 mb-2" color="gray" @click="removeMultiModalShow = false">Cancel</va-button>
        <va-button class="mr-2 mb-2" color="danger" @click="removeMultiModalConfirm">Delete All</va-button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import _ from 'lodash'
import { isMobile } from 'mobile-device-detect'

export default {
  mounted: async function () {
    if (!this.loader) {
      this.loader = this.$loading.show()
    }
    this.$store.commit('setSelectedItems', [])
    this.sessionsObject = {}
    try {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      // Load a list of sessions
      this.loadSessions()

      // Load Users
      const users = await axios.get(`${process.env.VUE_APP_API_URL}/api/users`, { headers })
      this.usersSelect = await Promise.all(users.data.users.map((user) => user.username))
      this.usersSelect.sort()
    } catch (error) {
      this.$router.push({ name: 'login', query: { redirect: '/pages/jointsessions' } })
    }
    if (this.isMobile) {
      this.columns = [
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        },
        {
          key: 'label',
          label: 'Sessions',
          headerTitle: 'Sessions',
          sortable: true
        },
        {
          key: 'mode',
          label: 'Mode',
          headerTitle: 'mode',
          sortable: true
        },
        {
          key: 'address',
          label: 'Address',
          headerTitle: 'Address',
          sortable: true
        },
        {
          key: 'user',
          label: 'User',
          headerTitle: 'User',
          sortable: true
        },
        {
          key: 'timestamp',
          label: 'Captured At',
          headerTitle: 'Captured At',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        }
      ]
    }
    if (this.loader) {
      this.loader.hide()
    }
  },
  data () {
    return {
      isMobile,
      loader: null,
      token: this.$store.state.token,
      sessions: [],
      filtered: [],
      sessionsObject: {},
      selectedItems: [],
      selectedItemsAll: [],
      columns: [
        {
          key: 'label',
          label: 'Sessions',
          headerTitle: 'Sessions',
          sortable: true
        },
        {
          key: 'mode',
          label: 'Mode',
          headerTitle: 'mode',
          sortable: true
        },
        {
          key: 'address',
          label: 'Address',
          headerTitle: 'Address',
          sortable: true
        },
        {
          key: 'user',
          label: 'User',
          headerTitle: 'User',
          sortable: true
        },
        {
          key: 'timestamp',
          label: 'Captured At',
          headerTitle: 'Captured At',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'Created on',
          headerTitle: 'Created on',
          sortable: true
        },
        {
          key: 'uid',
          label: 'Action',
          headerTitle: 'Action'
        }
      ],
      filterText: '',
      filterDateStart: null,
      filterDateEnd: null,
      filterTimeStart: null,
      filterTimeEnd: null,
      filters: localStorage.getItem('jointFilters') ? localStorage.getItem('jointFilters').split(',') : [],
      filtersTypes: localStorage.getItem('jointFiltersTypes') ? localStorage.getItem('jointFiltersTypes').split(',') : [],
      filterTypes: ['All', 'Name', 'Spatial', 'Temporal', 'Functional'],
      filterTypesAdvanced: ['Name', 'Spatial', 'Temporal', 'Functional'],
      filterSubTypes: ['User'],
      filterType: null,
      filterSubType: null,
      usersSelect: [],
      operators: ['AND', 'OR'],
      operatorsForFirst: ['AND'],
      operator: 'AND',
      perPage: localStorage.getItem('jointPerPage') ? parseInt(localStorage.getItem('jointPerPage')) : 10,
      perPageOptions: [5, 10, 25, 50, 100, 500, 1000],
      currentPage: localStorage.getItem('jointCurrentPage') ? parseInt(localStorage.getItem('jointCurrentPage')) : 1,
      removeModalShow: false,
      removeId: null,
      removeName: null,
      removeMultiModalShow: false,
      removeMultiNames: [],
      newId: null,
      pages: 0,
      sortBy: 'timestamp',
      sortOrder: 'desc',
      isSessionsLoading: false,
      newLoad: false,
      filterModeSetting: localStorage.getItem('filterModeSetting') === 'true' || false,
      advancedFilter: localStorage.getItem('advancedFilter') || '',
      filterSyntaxError: false
    }
  },
  computed: {
    filteredCount () {
      return this.filtered.length
    }
  },
  watch: {
    currentPage (newCurrentPage) {
      localStorage.setItem('jointCurrentPage', newJointCurrentPage)
      this.loadSessions()
    },
    perPage (newPerPage) {
      localStorage.setItem('jointPerPage', newJointPerPage)
      this.loadSessions()
    }
  },
  methods: {
    loadSessions: async function (filtering = false) {
      this.filterSyntaxError = false
      this.isSessionsLoading = true
      let selectedItemsAll = []
      if (!filtering) {
        selectedItemsAll = this.selectedItemsAll
      } else {
        this.selectedItems = []
      }
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        const filter_types = []
        const filter_values = []
        // Prepare filters
        this.filtersTypes.forEach((type, i) => {
          switch (type) {
            case 'All':
            case '|All':
              filter_types.push((type === '|All' ? '|' : '') + 'all')
              filter_values.push(this.filters[i])
              break
            case 'Name':
            case '|Name':
              filter_types.push((type === '|Name' ? '|' : '') + 'label')
              filter_values.push(this.filters[i])
              break
            case 'Spatial':
            case '|Spatial':
              filter_types.push((type === '|Spatial' ? '|' : '') + 'address')
              filter_values.push(this.filters[i])
              break
            case 'Temporal':
            case '|Temporal':
              filter_types.push((type === '|Temporal' ? '|' : '') + 'timestamp')
              if (this.filters[i].split('to').length <= 1) {
                const start_time = moment(`${this.filters[i]} 00:00`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                const end_time = moment(`${this.filters[i]} 23:59`, 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                filter_values.push(`${start_time} to ${end_time}`)
              } else {
                const start_time = moment(this.filters[i].split(' to ')[0], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                const end_time = moment(this.filters[i].split(' to ')[1], 'YYYY-MM-DD HH:mm').utc().format('YYYY-MM-DD HH:mm')
                filter_values.push(`${start_time} to ${end_time}`)
              }
              break
            case 'Functional - User':
            case '|Functional - User':
              filter_types.push((type === '|Functional - User' ? '|' : '') + 'user')
              filter_values.push(this.filters[i])
              break
            case 'Functional - Data Mode':
            case '|Functional - Data Mode':
              filter_types.push((type === '|Functional - Data Mode' ? '|' : '') + 'mode')
              filter_values.push(this.filters[i])
              break
            default:
              break
          }
        })

        let url = `${process.env.VUE_APP_API_URL}/api/jointsessions?page_no=${this.currentPage}&per_page=${this.perPage}&sort_by=${this.sortOrder === 'asc' ? this.sortBy : `-${this.sortBy}`}`

        if (this.filterModeSetting) {
          if (this.advancedFilter && this.advancedFilter.length > 0) {
            url = `${url}&filters=${this.advancedFilter}`
          }
        } else {
          if (filter_types.length > 0) {
            url = `${url}&filter_types=${filter_types.toString()}&filter_values=${filter_values.toString()}`
          }
        }
        // Load a list of sessions
        const sessions = await axios.get(url, { headers })
        this.sessions = await Promise.all(sessions.data.jointsessions.map(async (session) => {
          session.created_at = moment.utc(session.created_at).toDate()
          session.created_at = moment(session.created_at).local().format('YYYY-MM-DD hh:mm a')
          session.timestamp = moment.utc(session.timestamp).format('YYYY-MM-DD hh:mm a')
          this.sessionsObject[session.uid] = session.label
          return session
        }))
        this.filtered = this.sessions
        this.pages = sessions.data.total_pages
      } catch (error) {
        if (error.response && error.response.status && error.response.status === 400) {
          this.filterSyntaxError = true
        } else {
          this.$router.push({ name: 'login', query: { redirect: '/pages/joinsessions' } })
        }
      }
      this.isSessionsLoading = false
      this.newLoad = true
      this.selectedItemsAll = selectedItemsAll
    },
    removeModal (id) {
      this.removeId = id
      this.removeName = this.sessionsObject[id]
      this.removeModalShow = true
    },
    removeModalConfirm: async function () {
      if (!this.loader) {
        this.loader = this.$loading.show()
      }
      try {
        const headers = {
          Authorization: `Bearer ${this.token}`
        }
        // Delete a joint session
        await axios.delete(`${process.env.VUE_APP_API_URL}/api/jointsessions/${this.removeId}`, { headers })
        // Show success message
        this.$vaToast.init({
          message: 'Joint Session deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.loadSessions(true)

        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
      } catch (error) {
        this.removeId = null
        this.removeName = null
        this.removeModalShow = false
        this.$router.push({ name: 'login', query: { redirect: '/pages/jointsessions' } })
      }
      if (this.loader) {
        this.loader.hide()
      }
    },
    removeMultiModal: async function () {
      this.removeMultiNames = await Promise.all(this.selectedItemsAll.map((selectedItem) => selectedItem.label))
      this.removeMultiModalShow = true
    },
    removeMultiModalConfirm: async function () {
      if (!this.loader) {
        this.loader = this.$loading.show()
      }
      try {
        // Delete joint sessions
        await Promise.all(this.selectedItemsAll.map((selectedItem) => this.removeItem(selectedItem.uid)))
        // Show success message
        this.$vaToast.init({
          message: 'Joint Sessions deleted successfully!',
          iconClass: 'fa-star-o',
          position: 'bottom-right',
          duration: 6000,
          fullWidth: false,
          color: 'primary',
        })

        this.loadSessions(true)

        this.selectedItems = []
        this.selectedItemsAll = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
      } catch (error) {
        this.selectedItems = []
        this.selectedItemsAll = []
        this.removeMultiNames = []
        this.removeMultiModalShow = false
        this.$router.push({ name: 'login', query: { redirect: '/pages/jointsessions' } })
      }
      if (this.loader) {
        this.loader.hide()
      }
    },
    removeItem (id) {
      const headers = {
        Authorization: `Bearer ${this.token}`
      }
      return axios.delete(`${process.env.VUE_APP_API_URL}/api/jointsessions/${id}`, { headers })
    },
    visualizeFiles: async function () {
      this.$store.commit('setSelectedItems', this.selectedItemsAll)
      this.$router.push({ name: 'jointgrid' })
    },
    addFilter: function () {
      switch (this.filterType) {
        case 'All':
        case 'Name':
        case 'Spatial':
          if (this.filterText && this.filterText.length > 0) {
            this.filters.push(this.filterText.trim())
            this.filtersTypes.push((this.operator === 'OR' ? '|' : '') + this.filterType)
          }
          break
        case 'Temporal':
          if (this.filterDateStart) {
            const startDate = new Date(this.filterDateStart).toISOString().split('T')[0]
            const endDate = this.filterDateEnd ? new Date(this.filterDateEnd).toISOString().split('T')[0] : null
            const startTime = this.filterTimeStart ? `${this.filterTimeStart.toString().split(' ')[4].split(':')[0]}:${this.filterTimeStart.toString().split(' ')[4].split(':')[1]}` : '00:00'
            const endTime = this.filterTimeEnd && this.filterDateEnd ? `${this.filterTimeEnd.toString().split(' ')[4].split(':')[0]}:${this.filterTimeEnd.toString().split(' ')[4].split(':')[1]}` : '23:59'
            let tempDate = `${startDate} ${startTime}`
            if (endDate) {
              tempDate = `${tempDate} to ${endDate} ${endTime}`
            } else {
              tempDate = startDate
            }
            this.filters.push(tempDate)
            this.filtersTypes.push((this.operator === 'OR' ? '|' : '') + this.filterType)
          }
          break
        case 'Functional':
          if (this.filterText && this.filterText.length > 0) {
            if (this.filterSubType === 'User') {
              this.filters.push(this.filterText.join(';').trim())
            } else {
              this.filters.push(this.filterText.trim())
            }
            this.filtersTypes.push((this.operator === 'OR' ? '|' : '') + `${this.filterType} - ${this.filterSubType}`)
          }
          break
        default:
      }
      localStorage.setItem('jointFilters', this.filters)
      localStorage.setItem('jointFiltersTypes', this.filtersTypes)
      this.filterText = ''
      this.filterType = ''
      this.operator = 'AND'
      this.loadSessions(true)
    },
    addFilterAdvanced: function () {
      this.filterSyntaxError = false
      switch (this.filterType) {
        case 'All':
        case 'Name':
        case 'Spatial':
          if (this.filterText && this.filterText.length > 0) {
            this.advancedFilter = `${this.advancedFilter} ${this.filterType} =~ "${this.filterText.trim()}"`
          }
          break
        case 'Temporal':
          if (this.filterDateStart) {
            const startDate = new Date(this.filterDateStart).toISOString().split('T')[0]
            const endDate = this.filterDateEnd ? new Date(this.filterDateEnd).toISOString().split('T')[0] : startDate
            const startTime = this.filterTimeStart ? `${this.filterTimeStart.toString().split(' ')[4].split(':')[0]}:${this.filterTimeStart.toString().split(' ')[4].split(':')[1]}` : '00:00'
            const endTime = this.filterTimeEnd && this.filterDateEnd ? `${this.filterTimeEnd.toString().split(' ')[4].split(':')[0]}:${this.filterTimeEnd.toString().split(' ')[4].split(':')[1]}` : '23:59'
            let tempDate = `${startDate} ${startTime} to ${endDate} ${endTime}`
            this.advancedFilter = `${this.advancedFilter} ${this.filterType} <> "${tempDate}"`
          }
          break
        case 'Functional':
          if (this.filterText && this.filterText.length > 0) {
            if (this.filterSubType === 'User') {
              this.advancedFilter = `${this.advancedFilter} User = "${this.filterText.join(';').trim()}"`
            } else {
              this.advancedFilter = `${this.advancedFilter} Data Mode = "${this.filterText.trim()}"`
            }
          }
          break
        default:
      }
      localStorage.setItem('advancedFilter', this.advancedFilter)
      this.filterText = ''
      this.filterType = ''
      this.operator = 'AND'
    },
    removeFilter: function (index) {
      this.filters.splice(index, 1)
      this.filtersTypes.splice(index, 1)

      // Check if first filter is OR
      if (this.filtersTypes[0] && this.filtersTypes[0].startsWith('|')) {
        // Adjust other filters accordingly
        let andFilter = null
        this.filtersTypes.forEach((ftype, i) => {
          if (!andFilter && !ftype.startsWith('|')) {
            andFilter = i
          }
        })

        if (andFilter) {
          // Move it to front
          let tempFilter = this.filters[andFilter]
          let tempFilterType = this.filtersTypes[andFilter]

          this.filters.splice(andFilter, 1)
          this.filtersTypes.splice(andFilter, 1)

          this.filters.unshift(tempFilter)
          this.filtersTypes.unshift(tempFilterType)
        } else {
          // Remove OR from the first one
          this.filtersTypes[0] = this.filtersTypes[0].split('|')[1]
        }
      }

      localStorage.setItem('jointFilters', this.filters)
      localStorage.setItem('jointFiltersTypes', this.filtersTypes)
      this.loadSessions(true)
    },
    sortChange (sorted) {
      if (sorted.sortBy && sorted.sortingOrder && (this.sortBy !== sorted.sortBy || this.sortOrder !== sorted.sortingOrder)) {
        this.sortBy = sorted.sortBy
        this.sortOrder = sorted.sortingOrder
        this.loadSessions()
      }
    },
    selectionChange (selectedItems) {
      if (selectedItems.currentSelectedItems.length > selectedItems.previousSelectedItems.length) {
        const difference = selectedItems.currentSelectedItems.filter(x => !selectedItems.previousSelectedItems.includes(x))
        difference.forEach((item) => {
          this.selectedItemsAll.push(item)
        })
      } else {
        if (!this.newLoad) {
          const difference = selectedItems.previousSelectedItems.filter(x => !selectedItems.currentSelectedItems.includes(x))
          difference.forEach((item) => {
            _.remove(this.selectedItemsAll, item)
          })
        } else {
          this.sessions.forEach((session) => {
            this.selectedItemsAll.forEach((sessionSelected) => {
              if (session.uid === sessionSelected.uid) {
                this.selectedItems.push(session)
              }
            })
          })
          this.newLoad = false
        }
      }
    },
    clearSelection () {
      this.selectedItems = []
      this.selectedItemsAll = []
    },
    filterMode () {
      this.filterSyntaxError = false
      this.filterModeSetting = !this.filterModeSetting
      localStorage.setItem('filterModeSetting', this.filterModeSetting)
      this.loadSessions(true)
    },
    addAND () {
      this.filterSyntaxError = false
      this.advancedFilter = `${this.advancedFilter} AND`
    },
    addOR () {
      this.filterSyntaxError = false
      this.advancedFilter = `${this.advancedFilter} OR`
    },
    applyAdvancedFilter () {
      this.filterSyntaxError = false
      localStorage.setItem('advancedFilter', this.advancedFilter)
      this.loadSessions(true)
    }
  },
}
</script>

<style lang="scss">
  .markup-tables {
    .table-wrapper {
      overflow: auto;
    }

    .va-table {
      width: 100%;
    }
  }

  .table-example--pagination {
    text-align: right;
    text-align: -webkit-right;
    padding-top: 1rem;
  }

  .link {
     cursor: pointer;
     color: var(--va-primary);
  }

  .link:hover {
    text-decoration: underline;
  }

  .va-data-table {
    --va-data-table-thead-color: var(--va-primary);
  }

  .va-pagination__input {
    background-color: var(--va-white);
  }

  .va-alert {
    background-color: var(--va-white) !important;
  }

  .va-modal__dialog {
    background-color: var(--va-white);
    color: var(--va-dark);
  }

  .va-date-picker {
    --va-date-picker-text-color: var(--va-pure-dark);
  }

  .chip-hover:hover {
    cursor: pointer;
    background: var(--va-danger) !important;
  }

  .experiment-icon:hover {
    cursor: pointer;
  }
</style>
